/* Scope all styles under the .founder-section-container class */
.founder-section-container {
    font-family: Arial, sans-serif;
}

.founder-section-container .founder-header {
    background-color: #4CAF50;
    font-weight: bold;
    font-size: 50px;
    border-radius: 8px;
    margin-bottom: 40px;
    color: #333;      
    padding: 20px 0;
    text-align: center;
}

.founder-section-container .founder-header h1 {
    margin: 0;
    font-size: 40px;
    font-weight: bold;
}

.founder-section-container .founder-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
    background-color: #f0f0f0;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    max-width: 900px;
}

.founder-section-container .founder-photo {
    width: 100%;
    height: auto;
    max-width: 500px;
    object-fit: cover;
    margin-bottom: 20px;
    background-color: #fff;
}

.founder-section-container .founder-info {
    max-width: 800px;
    text-align: left;
    padding: 0 20px;
}

.founder-section-container .founder-info h2 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
    text-align: center;
}

.founder-section-container .founder-info p {
    font-size: 18px;
    color: #555;
    line-height: 1.6;
    text-align: justify;
}

.founder-section-container .objectives-section {
    margin-top: 30px;
    padding: 20px;
    background-color: #e9e9e9;
    border-radius: 8px;
    width: 100%;
    line-height: 1.6;
    max-width: 800px;
    text-align: justify;
}

.founder-section-container .objectives-section h3 {
    font-size: 24px;
    margin-bottom: 15px;
    color: #333;
    text-align: center;
}

.founder-section-container .objectives-section ol {
    font-size: 18px;
    color: #555;
    line-height: 1.6;
    padding-left: 20px;
}
