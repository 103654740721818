.principal-message {
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #f7f7f7;
}

.mssg {
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    color: black;
    padding: 20px;
    background-color: #4CAF50; /* Green background for the title */
    border-radius: 8px;
    margin-bottom: 25px;
  }

.header h1 {
    font-size: 2rem;
    margin: 0;
}

.header nav {
    font-size: 0.9rem;
    margin-top: 10px;
}

.header nav a {
    color: #333;
    text-decoration: none;
}

.header nav a:hover {
    text-decoration: underline;
}

.container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    max-width: 1200px; /* Increase the max-width for a larger container */
    margin: 0 auto;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.left-container {
    flex: 1;
    max-width: 50%; /* Ensure the image container takes half of the parent container's width */
    padding: 20px;
    text-align: center;
}

.left-container img {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.principal-details {
    margin-top: 10px;
}

.principal-details h3 {
    font-size: 1.5rem;
    margin: 0;
}

.principal-details p {
    font-size: 1.1rem;
    margin: 5px 0;
}

.right-container {
    flex: 1;
    max-width: 50%; /* Ensure the message container takes half of the parent container's width */
    padding: 20px;
}

.right-container p {
    font-size: 1rem;
    line-height: 1.6;
    margin: 10px 0;
}
@media (max-width: 768px) {
    .container {
        flex-direction: column; /* Stack containers vertically on small screens */
    }

    .left-container, .right-container {
        max-width: 100%;
        padding: 10px;
    }

    .mssg {
        font-size: 22px; /* Adjust title font size */
    }

    .principal-details p {
        font-size: 16px; /* Adjust principal details font size */
    }

    .right-container p {
        font-size: 14px; /* Adjust message font size */
    }
}
