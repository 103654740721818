/* src/Footer.css */

/* Reset CSS for all elements in the footer */
footer, footer * {
    border: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  footer {
    background-color: #1a237e;
    color: #fff;
    padding: 20px 0;
  }
  
  footer .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    background-color: #1a237e;
    border-color: #1a237e;
  }
  
  footer .section {
    flex: 1;
    min-width: 300px; /* Ensures sections don't get too narrow */
    margin: 0 10px; /* Ensures equal spacing between sections */
  }
  
  footer .logo-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  footer .logo {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #1a237e; /* Optional: adds a border around the logo */

  }
  
  footer h2,
  footer h3 {
    margin-bottom: 10px;
    color: hwb(0 98% 0%); /* Ensures the headers are white */
  }
  
  footer .address {
    color: #fff !important; /* Sets the address text to white */
  }
  
  footer .address p {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    color: #fff; /* Ensures the address text is white */
  }
  
  footer .icon {
    margin-right: 10px;
    color: #fff; /* Ensures the icons are white */
  }
  
  footer p,
  footer ul {
    margin: 0;
    padding: 0;
  }
  
  footer ul {
    list-style-type: none;
  }
  
  footer ul li {
    margin-bottom: 5px;
  }
  
  footer ul li a {
    color: #fff;
    text-decoration: none;
  }
  
  footer ul li a:hover {
    text-decoration: underline;
  }
  
  footer iframe {
    width: 100%;
    height: 200px;
    border: none; /* Ensure no border on iframe */
  }
  
  footer .bottom-bar {
    text-align: center;
    padding: 10px 0;
    border-top: none; /* Ensure no border on bottom bar */
    color: #fff; /* Ensures the bottom bar text is white */
  }
  
  footer .bottom-bar p {
    color: #fcf7f7;
    border-top: 1px solid #0a1bdc; /* Adjust color and size as needed */

  }
.linkedin{
  color: #fcf7f7
}