.activities-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.activity {
    flex: 1 1 calc(50% - 40px);
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 10px;
    box-sizing: border-box;
}

.activity h2 {
    font-size: 24px;
    margin-bottom: 15px;
    color: #333;
}

.images {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 15px;
}

.images img {
    width: 100%;
    max-width: calc(33.33% - 10px);
    height: auto;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    object-fit: cover;
}

.activity p {
    font-size: 16px;
    color: #555;
    line-height: 1.5;
    text-align: justify;
}

/* Responsive Design */
@media (max-width: 768px) {
    .activity {
        flex: 1 1 100%;
    }

    .images img {
        max-width: calc(50% - 10px);
    }
}

@media (max-width: 480px) {
    .images img {
        max-width: 100%;
    }

    .activity p {
        font-size: 14px;
    }
}
