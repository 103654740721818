/* src/board.css */
.title {
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  color: white;
  padding: 20px;
  background-color: #4CAF50; /* Green background for the title */
  border-radius: 8px;
  margin-bottom: 40px;
}

.board-member {
  text-align: center;
  margin-bottom: 40px;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff; /* White background for each member container */
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow for depth */
  transition: transform 0.2s; /* Smooth hover effect */
}

.board-member:hover {
  transform: translateY(-10px); /* Lift effect on hover */
}

.board-member-photo-container {
  margin-bottom: 10px;
}

.board-member-photo {
  width: 150px; /* Fixed size */
  height: 150px;
  border-radius: 8px; /* Square photos with rounded corners */
  object-fit: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.board-member-info {
  width: 100%;
}

.board-member-designation-container {
  margin-bottom: 10px;
}

.board-member-designation {
  font-weight: bold;
  font-size: 25px; /* Increased font size */
  color: #21225f;
  text-align: center;
  margin-bottom: 10px;
}

.board-member-details {
  margin-top: 10px;
  text-align: center;
}

.board-member-name {
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 20px;
  color:black;
}

.board-member-experience {
  font-size: 16px;
  color: black;
}

.board-member-message-intro {
  font-weight: bold;
  text-align: left;
  font-size: 18px;
  width: 100%;
  margin: 10px 0 5px;
}

.board-member-message {
  font-size: 17px;
  text-align: justify;
  line-height: 1.6; /* Improved line spacing for readability */
}
