.about-school-wrapper {
  padding: 20px;
  background-color: #fff;
  margin: 20px auto;
  max-width: 1200px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.section-title {
  text-align: center;
  color: #0056b3;
  font-weight: bold;
  font-size: 26px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.about-school-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.image-container {
  flex: 1 1 50%; /* Flexible basis for responsiveness */
  max-width: 50%; /* Ensure the image container takes half of the screen */
  padding-right: 20px; /* Add padding to the right */
  box-sizing: border-box; /* Ensure padding is included in the width */
}

.image-container img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.info-container {
  flex: 1 1 50%; /* Flexible basis for responsiveness */
  max-width: 50%; /* Ensure the text container takes half of the screen */
}

.info-container p {
  font-size: 18px;
  line-height: 1.6;
  color: #333;
  text-align: justify;
  margin-bottom: 20px;
}

.full-width-paragraph {
  font-size: 18px;
  line-height: 1.6;
  color: #333;
  text-align: justify;
  margin-bottom: 20px;
}
/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .about-school-container {
    flex-direction: column;
  }
  
  .image-container {
    max-width: 100%;
    padding-right: 0;
  }

  .info-container {
    max-width: 100%;
  }

  .info-container p,
  .full-width-paragraph {
    font-size: 16px;
    line-height: 1.4;
  }
}