/* cards.css */

body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    margin-top: 125px;
  }
  
  .photo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-bottom: 100px; /* Ensure space for footer */
    background-color: #f5f5f5; /* Light background color */
    padding: 40px; /* Padding around the container */  
  }
  
  .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px; /* Add margin bottom to separate from footer */
  }
  
  .card {
    width: 300px;
    margin: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.3s;
  }
  
  .card:hover {
    transform: scale(1.05);
  }
  
  .card-image {
    width: 100%;
    height: 250px; /* Increased height for larger image */
    object-fit: cover;
  }
  
  .card-content {
    padding: 20px;
  }
  
  .card h2 {
    margin: 0 0 10px;
  }
  
  .description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 60px;
  }
  .activity-details {
    width: 100%; /* Full width for container */
    max-width: 1200px; /* Set a maximum width for large screens */
    padding: 40px; /* Padding */
    background: white; /* White background for details */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow for depth */
    border-radius: 8px; /* Rounded corners */
    text-align: center; /* Center align content */
  }
  
  .activity-details h2 {
    margin: 20px 0 20px;
    font-size: 32px; /* Font size */
    color: #333; /* Font color */
    text-transform: uppercase; /* Uppercase text */
    text-align: center; /* Center align text */
    border-bottom: 2px solid #007bff; /* Border bottom for separation */
    display: inline-block; /* Inline block for centered border */
    padding-bottom: 10px; /* Padding below the text */
  }
  
  .activity-details p {
    margin: 20px 0;
    text-align: justify; /* Justify text */
    color: #555; /* Changed font color */
    font-size: 18px;
    line-height: 1.6; /* Improved line height */
  }
  
  .additional-images {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Increased gap for spacing */
    justify-content: center;
    margin-top: 20px; /* Add margin top for spacing */
  }
  
  .additional-images img {
    width: 250px; /* Image size */
    height: 250px; /* Maintain square aspect ratio */
    object-fit: cover;
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for depth */
  }
  
  .back-button {
    padding: 10px 20px;
    margin-bottom: 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px; /* Font size */
    display: block;
    margin: 0 auto 20px; /* Center align button and add margin */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for button */
  }
  
  .back-button:hover {
    background-color: #0056b3;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .activity-details {
      padding: 20px; /* Reduce padding for smaller screens */
    }
  
    .additional-images img {
      width: 45%; /* Increase image size for smaller screens */
      height: auto; /* Maintain aspect ratio */
    }
  }
  
  @media (max-width: 480px) {
    .activity-details {
      padding: 15px; /* Further reduce padding for mobile screens */
    }
  
    .additional-images img {
      width: 100%; /* Increase image size to full width */
      height: auto; /* Maintain aspect ratio */
    }
  }
  
    .footer {
    width: 100%;
    background-color: #f1f1f1;
    padding: 20px 0;
    text-align: center;
    position: absolute;
    bottom: 0;
  }
  