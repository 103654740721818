/* Global Styles */

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: Arial, sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    background-color: #f9f9f9;
}

/* Container Styles */

.container {
    max-width: 1000px; /* Increased max-width */
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; /* Ensure cards wrap on smaller screens */
}

/* Heading Styles */

.heading {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #1a237e; /* Color matching image 1 */
    margin-top: 160px; /* Ensure heading is below the nav bar */
    margin-bottom: 20px; /* Spacing below the heading */
}

/* Card Styles */

.card {
    background-color: #e3f2fd; /* Background color matching image 1 */
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    width: 30%; /* Adjusted width to fit three cards in a row */
    margin: 15px; /* Increased margin for more space between cards */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    text-align: center; /* Center text */
}

.card h2 {
    margin-top: 0;
    font-weight: bolder;
    font-size: 20px;
    color: #1a237e; /* Text color matching image 1 */
}

.card .content {
    margin-top: 10px;
    font-size: 16px;
    color: #666;
}

.card .content p {
    margin-bottom: 10px;
}
.cl{
    color:#1a237e;
    font-weight: bold;
}
/* Office Hours Styles */

.office-hours {
    margin-top: 20px;
}

.office-hours .card {
    padding: 10px;
}

.office-hours .card h2 {
    font-size: 16px;
}

.office-hours .card .content {
    font-size: 14px;
}

/* Visiting Hours Styles */

.visiting-hours {
    margin-top: 20px;
}

.visiting-hours .card {
    padding: 10px;
}

.visiting-hours .card h2 {
    font-size: 16px;
}

.visiting-hours .card .content {
    font-size: 14px;
}

.visiting-hours .card .content p {
    margin-bottom: 5px;
}

.visiting-hours .card .content p:last-child {
    margin-bottom: 10px;
}
/* Responsive Styles */
@media (max-width: 768px) {
    .container {
        flex-direction: column;
        align-items: center;
    }

    .card {
        width: 90%;
        margin: 10px 0;
    }
}

@media (max-width: 480px) {
    .heading {
        font-size: 20px;
    }

    .card h2 {
        font-size: 18px;
    }

    .card .content {
        font-size: 14px;
    }

    .card .content p {
        margin-bottom: 8px;
    }
}

/* Nav Bar Styles */

/* Assuming nav-bar styles are defined elsewhere in your application */
.contact-info {
    max-width: 1000px;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    text-align: center;
  }
  .contact-info h1 {
    color: #1a237e ;
    font-weight: bold;
  }
  .contact-info h2 {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }
  
  .contact-details {
    font-size: 16px;
    color: #333;
    text-align: centre;
  }
  
  .contact-details p {
    margin-bottom: 10px;
  }
  
  .contact-details i {
    margin-right: 10px;
  }
  
  .map-container {
    margin-top: 20px;
  }
  
  .map-container iframe {
    width: 100%;
    height: 450px;
    border: none;
    border-radius: 10px;
  }
