.results-container {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .result-image {
    margin-bottom: 40px;
    text-align: center;
  }
  
  .result-image img {
    max-width: 100%;
    height: auto;
    border: 2px solid #ccc;
    padding: 10px;
    background-color: #fff;
  }
  
  .result-table {
    margin-bottom: 40px;
  }
  
  .result-table h2 {
    text-align: center;
    font-size: 25px;
    margin-bottom: 7px;
  }
  .classes{
    font-weight: bold;
    color: #21225f;
  }
  .result-table h3 {
    text-align: center;
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .result-table table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .result-table th, .result-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .result-table th {
    background-color: #ffde00;
    font-weight: bold;
  }
  
  .result-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .result-table tr:hover {
    background-color: #f1f1f1;
  }
  