.achievements-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 20px;
}

.achievement {
  width: 48%; /* Default for larger screens */
  margin: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  background-color: #f9f9f9;
}

.achievement img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
}

.achievement h3 {
  margin: 15px 0;
  font-size: 22px;
  color: #333;
}

.achievement p {
  font-size: 18px;
  color: #666;
  margin-bottom: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .achievement {
    width: 100%; /* Full width on tablets and smaller devices */
    margin: 10px 0; /* Adjusted margin for better spacing */
  }

  .achievement h3 {
    font-size: 20px; /* Slightly smaller font size on smaller screens */
  }

  .achievement p {
    font-size: 16px; /* Slightly smaller font size on smaller screens */
  }
}

@media (max-width: 480px) {
  .achievement {
    width: 100%; /* Full width on mobile devices */
    margin: 10px 0;
  }

  .achievement h3 {
    font-size: 18px; /* Adjusted font size for mobile */
  }

  .achievement p {
    font-size: 14px; /* Adjusted font size for mobile */
  }
}
