.syllabus-wrapper {
    padding: 20px;
    background-color: #fff;
    margin: 20px auto;
    max-width: 1000px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .school-title {
    text-align: center;
    font-weight: bold;
    font-size: 28px;
    margin-bottom: 20px;
    color: #1e3d7b; /* Dark blue color */
  }
  .syllabus-wrapper h4 {
  text-align: center;
  color: #1e3d7b; /* Dark blue color */
font-weight: bolder;

  }
  .syllabus-content {
    font-size: 18px;
    line-height: 1.6;
    color: #333;
  }
  
  .syllabus-content h3 {
    font-size: 24px;
    font-weight: bolder;
    color: #1e3d7b; /* Dark blue color */
    margin-top: 20px;
  }
  
  .syllabus-content p {
    margin-bottom: 10px;
    padding-left: 20px; /* Add padding to move the content to the right */
    font-weight: 500;
  }
  
  .syllabus-content strong {
    font-weight: bold;
  }
  .extra{
    text-align: justify;
  }