/* Container for the entire scrolling section */
.scrolling-container {
  display: flex;
  align-items: center;
  background-color: #00237D; /* Blue background color */
  color: white; /* White text color */
  position: fixed;
  top: 0;
  width: 100%;
  height: 30px; /* Adjust height as needed */
  z-index: 1000; /* Ensure it's above other content */
}

/* Announcements label styled as an arrow */
.announcements-label {
  background-color: #FFD700; /* Yellow background color */
  color: black; /* Black text color */
  padding: 0 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  position: relative;
  clip-path: polygon(0 0, 90% 0, 100% 50%, 90% 100%, 0 100%);
}

/* Styles for the scrolling text */
.scrolling-text {
  overflow: hidden;
  white-space: nowrap;
  flex-grow: 1;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center; /* Center the text horizontally */
  height: 100%;
}

.scrolling-content {
  display: inline-block;
  animation: scroll 20s linear infinite; /* Adjust the animation duration as needed */
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.scrolling-item {
  display: inline-block;
  padding-left: 10px;
}

.bullet-point {
  color: #FFD700; /* Yellow color for the bullet points */
}

/* Hide the default scrollbar */
body::-webkit-scrollbar {
  display: none;
}
