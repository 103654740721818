/*.carousel{
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}*/
body {
  margin: 0;
  padding: 0;
  background-color: #e4e9f5;
}
.carousel img {
  height: auto;
  width:100%;
  max-height: 450px;
  object-fit: cover;
}

/*RIGHT AND LEFT ARROW CODE
.slide{
  border-radius: 0.5rem;
  box-shadow: 0px 0px 7px #666;
  width:100%;
  height:100%;
}*/
.carousel-control-prev-icon,
.carousel-control-next-icon {
    background-color: black; 
    width: 25px; 
    height: 25px;
    border-radius: 50%;
}

.carousel-control-prev-icon:after,
.carousel-control-next-icon:after {
    content: '';
    display: inline-block;
    width: 30px; 
    height: 20px; 
    background-size: 100% 100%;
}

.carousel-control-prev-icon:after {
    background-image: url(&#8592;); 
}

.carousel-control-next-icon:after {
    background-image: url(&#8592;);
}
.carousel-item img{
  width: 100%;
  height:auto;
  object-fit: cover;
}
/* css for the announcements Start */
.announcements-container {
  margin: 20px;
}
.announcements-title {
  text-align: left;
  font-size: 27px; /* Smaller font size */
  font-weight: bold;
  color: #1a5aaf;
  margin-bottom: 10px;
}

.buttons-container {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  flex-wrap: wrap; /* Allow buttons to wrap on smaller screens */

}

.announcement-button {
  display: flex;
  align-items: center;
  background-color: #1a5aaf;
  color: white;
  padding: 10px 20px;
  border-radius: 8px;
  width: auto;
  transition: transform 0.3s ease, background-color 0.3s ease;
  text-decoration: none;
  cursor: pointer;
  font-weight: bold; /* Make text bold */
  margin: 10px; /* Add margin for spacing */


}

.announcement-button:hover {
  transform: translateY(-5px);
  background-color: #1e2d70;
}

.icon {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.announcement-button span {
  font-size: 16px;
}
@media (max-width: 768px) {
  .announcements-title {
    text-align: center;
  }
  .buttons-container {
    flex-direction: column; /* Stack buttons vertically */
    align-items: center; /* Center align buttons */
  }
  .announcement-button {
    width: 80%; /* Make buttons wider on mobile */
    justify-content: center; /* Center text and icon */
  }
  .icon {
    margin-right: 5px; /* Reduce margin on smaller screens */
  }
}

/* announcement css end */

/* about us on main page start */

/* src/MainContent.css */
.parent {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  background-color: #fff;
}

.image {
  flex: 1;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image img {
  width: 100%;  /* Ensures the image fills its container */
  max-width: 900px;  /* Limits the image width on larger screens */
  height: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.content-section {
  flex: 2;
  min-width: 300px;
  padding: 10px;
  text-align: justify; /* Justify text alignment */
}

h5 {
  color: green;
  margin-top: 10px;
}

h2 {
  color: navy;
  margin-top: 25px;
}

.read-more-button {
  display: inline-block;
  background-color: green;
  color: white;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 10px;
}

.read-more-button:hover {
  background-color: darkgreen;
}

@media (max-width: 768px) {
  .parent {
    flex-direction: column;
  }

  .image {
    min-width: 100%; /* Ensure the image section takes full width on small screens */
    padding: 10px 0; /* Adjust padding for a better fit */
  }

  .image img {
    max-width: 100%; /* Ensure the image is responsive on small screens */
  }

  .content-section {
    padding: 10px 0; /* Adjust padding for a better fit */
  }

  h2, h5 {
    text-align: center;
  }

  .read-more-button {
    margin: 10px auto; /* Center the button */
    display: block;
    text-align: center;
  }
}

@media (min-width: 769px) {
  .image {
    min-width: 600px; /* Set a minimum width for larger screens */
    max-width: 900px; /* Set a maximum width for larger screens */
  }

  .content-section {
    padding: 20px; /* Adjust padding for better layout on larger screens */
  }
}

/*Principals css*/
.principal-message-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  gap: 80px;
}

.message-content {
  flex: 1;
  max-width: 50%;
  padding: 20px;
  border-radius: 8px;
}

.message-content h1 {
  font-size: 34px;
  color: black;
  font-weight: bold;
}

.message-content p {
  margin-top: 40px;
  font-size: 16px;
  line-height: 1.6;
  text-align: justify;
}

.view-more-button {
  display: inline-block;
  background-color: green;
  color: white;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 15px;
}

.view-more-button:hover {
  background-color: #45a049;
}

.principal-image-section {
  flex: 1;
  text-align: center;
  box-shadow: 0 4px 8px #f4f0f01a;
}

.principal-image-section img {
  max-width: 100%;
  border-radius: 8px;
  box-shadow: white;
}

.principal-details {
  margin-top: 10px;
  text-align: center;
}

.principal-details h3 {
  color: navy;
  font-size: 20px;
  margin: 5px 0;
}

.principal-details h5 {
  color: navy;
  font-size: 18px;
}

.principal-details p {
  color: black;
  font-size: 16px;
  margin: 2px 0;
  font-weight: 540;
}

@media (max-width: 768px) {
  .principal-message-container {
    flex-direction: column;
    align-items: center;
    padding: 10px;
    gap: 20px; /* Reduced gap */
  }

  .message-content {
    max-width: 100%;
    text-align: center;
    order: 2; /* Show below image on mobile */
  }

  .message-content h1 {
    font-size: 28px;
  }

  .message-content p {
    margin-top: 20px;
    font-size: 16px; /* Increased text size */
    text-align: justify;
  }

  .view-more-button {
    margin-top: 20px;
    padding: 8px 16px;
  }

  .principal-image-section {
    max-width: 80%;
    margin-top: 20px;
    order: 1; /* Show above message on mobile */
  }

  .principal-details h3 {
    font-size: 18px;
  }

  .principal-details h5 {
    font-size: 16px;
  }

  .principal-details p {
    font-size: 14px; /* Adjusted text size */
  }
}

@media (max-width: 480px) {
  .message-content h1 {
    font-size: 24px;
  }

  .message-content p {
    font-size: 14px; /* Adjusted text size */
  }

  .view-more-button {
    padding: 6px 12px;
  }

  .principal-image-section {
    max-width: 90%;
  }

  .principal-details h3 {
    font-size: 16px;
  }

  .principal-details h5 {
    font-size: 14px;
  }

  .principal-details p {
    font-size: 12px; /* Adjusted text size */
  }
}
/*VISION AND MISSION */
.vision-mission-container{
  display: flex;
  justify-content: center;
  padding:20px;
}
.vision-mission-content{
  display:flex;
  align-items: stretch;
  gap:0;
  width: 100%;
  max-width: 1200px;
}

.image-section {
  flex: 1;
  text-align:center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-section img{
  max-width:100%;
  height: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.text-section {
  flex: 1;
  max-width: 55%;
  background-color: #fff;
  padding-left: 20px; /* Add some padding to separate from the image */
}
.text-section h3{
  color:#4CAF50;
  font-size: 18px;
  margin-bottom: 10px;
}
.text-section h2{
  color: #1a5aaf;
  font-size: 24px;
  margin-bottom: 20px;
}

.mission-statement h4{
  color:#1a5aaf;
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.mission-statement p{
  color:#333;
  font-size: 16px;
  line-height: 1.5;
  margin:0;
}
@media (max-width: 768px) {
  .vision-mission-content {
      flex-direction: column;
      align-items: flex-start;
  }

  .image-section, .text-section {
      max-width: 100%;
  }

  .text-section {
      padding: 20px;
  }
}