/* Ensure the navbar remains fixed at the top */
.navbar {
  position: fixed;
  width: 100%;
  top: 30px;
  z-index: 1000;
}

/* Styling for the scrolling text container */
.scrolling-container {
  padding-top: 0;
  background-color: #f8f9fa;
}

/* Logo styles */
.logo {
  height: 60px; /* Adjust size as needed */
  margin-right: 10px;
}

/* Header styles */
.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* School name and sub-name styles */
.school-name, .sub-name {
  display: block;
  text-align: center;
}

.school-name {
  font-weight: bold;
  color: #1a5aaf;
  font-size: 24px;
}

.sub-name {
  font-size: 23px;
  font-weight: bold;
  color: #1a5aaf;
  margin-top: -5px;
}

/* Navbar link styles */
.navbar-nav .nav-link {
  color: black; /* Adjust color as needed */
  position: relative;
}

.navbar-nav .nav-link:hover {
  color: #007bff; /* Bootstrap's primary color on hover */
}

.nav-item {
  margin-right: 10px;
  margin-left: 20px;
}

.nav-item:hover .nav-link::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -5px;
  height: 2px;
  background-color: blue;
}

/* Dropdown menu styles */
.nav-item.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-menu {
  background-color: #e6f2ff; /* Different color for dropdown box */
  border: none;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  margin-top: 0;
}

.dropdown-item {
  color: #555;
  padding: 10px 20px; /* Adjust padding for margin between items */
  border-bottom: 1px solid #dcdcdc; /* Light grey line between items */
}

.dropdown-item:last-child {
  border-bottom: none; /* Remove border from the last item */
}

.dropdown-item:hover {
  color: #007bff;
  background-color: #d0e6ff; /* Different hover color for dropdown items */
}

/* Ensure padding at the top of the body to accommodate the fixed navbar */
body {
  padding-top: 10px;
}

/* Additional styles for dropdown items */
.dropdown-item {
  color: #555;
  padding: 10px 20px; /* Adjust padding for margin between items */
  border-bottom: 1px solid #dcdcdc; /* Light grey line between items */
}

.dropdown-item:last-child {
  border-bottom: none; /* Remove border from the last item */
}
