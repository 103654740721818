body {
  font-family: Arial, sans-serif;
}

.dress-code {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
}

.title {
  text-align: center;
  font-size: 40px; /* Increased font size */
  font-weight: bold;
  margin-bottom: 40px;
  color: #333;
}

.container1 {
  background-color: white;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.subtitle1 {
  font-size: 28px; /* Increased font size */
  font-weight: bold;
  margin-bottom: 10px;
  color: white;
  background-color: #1e3a8a;
  text-align: center;
  padding: 15px 0; /* Ensure full line background color */
  border-radius: 10px 10px 0 0;
  width: 100%; /* Ensures subtitle spans the entire container width */
}

.heading {
  font-size: 22px; /* Increased font size */
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #1e3a8a;
}

.group {
  margin-bottom: 20px;
  width: 100%;
}

.list {
  list-style: none;
  padding-left: 0;
}

.list li {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  font-size: 18px; /* Increased font size */
  color: #333;
}
